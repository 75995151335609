import request from "@/utils/request";
// 交易订单查询
export function getDealOrderList(data) {
  return request("post", "/apm/request/page", data);
}

// 交易订单详情查询
export function getDealOrderInfo(data) {
  return request("post", "/apm/request/detailList", data);
}

// 交易订单余额查询
export function getDealOrderBalance(data) {
  return request("post", "/apm/request/queryBalance", data);
}
